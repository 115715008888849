import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Frontend Development",
      desc: " I excel in React, MUI, and Ant Design.Whether you need a simple landing page or a complex web application, I have the skills and knowledge to leverage these tools to deliver high-quality results.",
      icon: "fa fa-paint-brush",
    },
    {
      name: "Backend Development",
      desc: "I specialize in backend development using Node.js and MySQL.With my experience, I can help you create a strong backend foundation for your web application or mobile app.",
      icon: "fa fa-database",
    },
    {
      name: "Server Management",
      desc: "I have experience in maintaining and configuring servers for optimal performance and uptime.With my expertise in server management, you can trust that your server is in good hands.",
      icon: "fa fa-server",
    }
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
